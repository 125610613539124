<template>
  <validation-observer ref="validationObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Konfiguracja</h4>
        <b-form-group label="Ocena klientów" label-for="v-rating">
          <validation-provider
            #default="{ errors }"
            name="Ocena klientów"
            rules="required|positive|decimal:2|max_value:5">
            <b-form-input
              v-model="formData.rating"
              type="number"
              step="0.01"
              min="0"
              max="5"
              id="v-rating"
              placeholder="Ocena klientów" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Liczba produktów" label-for="v-products">
          <validation-provider #default="{ errors }" name="Liczba produktów" rules="required|positive|alpha_num">
            <b-form-input v-model="formData.products" type="number" id="v-products" placeholder="Liczba produktów" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Liczba pracowników" label-for="v-employees">
          <validation-provider #default="{ errors }" name="Liczba pracowników" rules="required|positive|alpha_num">
            <b-form-input
              v-model="formData.employees"
              type="number"
              id="v-employees"
              placeholder="Liczba pracowników" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Centrum logistyczne - powierzchnia (m2)" label-for="v-logistic_center">
          <validation-provider
            #default="{ errors }"
            name="Centrum logistyczne - powierzchnia (m2)"
            rules="required|positive|alpha_num">
            <b-form-input
              v-model="formData.logistic_center"
              type="number"
              id="v-logistic_center"
              placeholder="Centrum logistyczne - powierzchnia (m2)" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Liczba miejsc paletowych" label-for="v-pallet_places">
          <validation-provider
            #default="{ errors }"
            name="Liczba miejsc paletowych"
            rules="required|positive|alpha_num">
            <b-form-input
              v-model="formData.pallet_places"
              type="number"
              id="v-pallet_places"
              placeholder="Liczba miejsc paletowych" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col class="mt-5">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary"> Zapisz </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';

export default {
  mixins: [NotificationMixin],
  props: {
    data: {
      type: [Object, null],
      required: true,
    },
  },

  data() {
    const data = this.data;

    return {
      formData: data,
    };
  },

  methods: {
    onSubmit() {
      this.$emit('submit', this.formData);
    },
    validationForm() {
      this.$refs.validationObserver.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }
        this.showFormValidationNotification();
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
