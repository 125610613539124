var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validationObserver"},[_c('b-form',{staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-col',[_c('h4',{staticClass:"mb-2"},[_vm._v("Konfiguracja")]),_c('b-form-group',{attrs:{"label":"Ocena klientów","label-for":"v-rating"}},[_c('validation-provider',{attrs:{"name":"Ocena klientów","rules":"required|positive|decimal:2|max_value:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","step":"0.01","min":"0","max":"5","id":"v-rating","placeholder":"Ocena klientów"},model:{value:(_vm.formData.rating),callback:function ($$v) {_vm.$set(_vm.formData, "rating", $$v)},expression:"formData.rating"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Liczba produktów","label-for":"v-products"}},[_c('validation-provider',{attrs:{"name":"Liczba produktów","rules":"required|positive|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"v-products","placeholder":"Liczba produktów"},model:{value:(_vm.formData.products),callback:function ($$v) {_vm.$set(_vm.formData, "products", $$v)},expression:"formData.products"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Liczba pracowników","label-for":"v-employees"}},[_c('validation-provider',{attrs:{"name":"Liczba pracowników","rules":"required|positive|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"v-employees","placeholder":"Liczba pracowników"},model:{value:(_vm.formData.employees),callback:function ($$v) {_vm.$set(_vm.formData, "employees", $$v)},expression:"formData.employees"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Centrum logistyczne - powierzchnia (m2)","label-for":"v-logistic_center"}},[_c('validation-provider',{attrs:{"name":"Centrum logistyczne - powierzchnia (m2)","rules":"required|positive|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"v-logistic_center","placeholder":"Centrum logistyczne - powierzchnia (m2)"},model:{value:(_vm.formData.logistic_center),callback:function ($$v) {_vm.$set(_vm.formData, "logistic_center", $$v)},expression:"formData.logistic_center"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Liczba miejsc paletowych","label-for":"v-pallet_places"}},[_c('validation-provider',{attrs:{"name":"Liczba miejsc paletowych","rules":"required|positive|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"v-pallet_places","placeholder":"Liczba miejsc paletowych"},model:{value:(_vm.formData.pallet_places),callback:function ($$v) {_vm.$set(_vm.formData, "pallet_places", $$v)},expression:"formData.pallet_places"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-5"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Zapisz ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }